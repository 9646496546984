// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-8-1!../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-8-1!../node_modules/@blueprintjs/core/lib/css/blueprint.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".board {\n  display: grid;\n  grid-template-columns: 33.33% 66.66%;\n}\n@media screen and (max-width: 1050px) {\n  .board {\n    grid-template-columns: 100%;\n  }\n}\n.board-2 {\n  display: grid;\n  grid-template-columns: 50% 50%;\n}\n@media screen and (max-width: 750px) {\n  .board-2 {\n    grid-template-columns: 100%;\n  }\n}\n.board-3 {\n  display: grid;\n  grid-template-columns: 100%;\n}\n@media screen and (max-width: 1050px) and (min-width: 750px) {\n  .board-3 {\n    grid-template-columns: 50% 50%;\n  }\n}\n.item {\n  margin: 5px;\n}\n.nav {\n  position: sticky;\n  top: 0;\n}\n.table {\n  width: 100%;\n}\n.progress-bar .progress-meter {\n  transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9);\n}\n.progress-bar {\n  color: #fff;\n  width: 100%;\n  height: 1.5rem;\n  overflow: hidden;\n  display: flex;\n  border-radius: 0.25rem;\n  background-color: #dee2e6;\n}\n.progress-meter {\n  text-align: center;\n  justify-content: center;\n  display: flex;\n  flex-direction: column;\n}\n.badge {\n  display: inline-block;\n  padding: 0.25em 0.4em;\n  font-size: 80%;\n  font-weight: 700;\n  line-height: 1;\n  text-align: center;\n  white-space: nowrap;\n  vertical-align: baseline;\n  border-radius: 0.25rem;\n  color: #fff;\n  margin-left: 0.25rem;\n}\nth {\n  text-align: left;\n}\n.logo {\n  width: 30px;\n}\n.auth-logo {\n  margin-right: 10px;\n  width: 25px;\n}\n.backdrop {\n  background-color: #fff;\n}\n.bp3-dialog {\n  width: 350px;\n  background-color: #fff;\n}\n.spinner {\n  position: fixed;\n  z-index: 999;\n  overflow: show;\n  margin: auto;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  width: 100px;\n  height: 100px;\n}\n.center-parent {\n  display: table;\n  height: 100%;\n}\n.center-child {\n  display: table-cell;\n  vertical-align: middle;\n}\n", ""]);
// Exports
module.exports = exports;
